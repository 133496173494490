import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import RenewToken from './RenewToken';
import VersionInfo from './components/Footer/VersionInfo';
import NotFound from './components/NotFound';
import Unauthorized from './components/Unauthorized/Unauthorized';
import Broadcast from './containers/Broadcast';
import BroadcastBanner from './containers/Broadcast/BroadcastBanner';
import BroadcastMesssageDetails from './containers/Broadcast/BroadcastMesssageDetails';
import { activeMessages } from './containers/Broadcast/selectors';
import Dashboard from './containers/DashBoard/';
import EmailAlertView from './containers/EmailAlertView';
import NetworkError from './containers/ErrorPages/NetworkError';
import ServerError from './containers/ErrorPages/ServerError';
import FleetSetup from './containers/FleetSetup';
import FleetView from './containers/FleetView';
import Glossary from './containers/Glossary';
import HeaderPage from './containers/Header';
import Help from './containers/Help';
import PackView from './containers/PackView';
import ReportView from './containers/ReportView';
import SafeMode from './containers/SafeMode';
import TractionConverterView from './containers/TractionConverterView';
import VehicleProductsView from './containers/VehicleProductsView';
import VehicleView from './containers/VehicleView';
import history from './history';
class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMsgDetails: false,
    };
  }

  handleClose = () => {
    this.setState({ showMsgDetails: false });
  };

  handleShow = () => {
    this.setState({ showMsgDetails: true });
  };

  render() {
    return (
      <Router history={history}>
        <Fragment>
          <header>
            <HeaderPage
              checkSafeModeStatus={this.props.checkSafeModeStatus}
              showMessages={this.handleShow}
              messageCount={
                this.props.activeMessages && this.props.activeMessages.length
              }
            />

            {this.props.activeMessages &&
              this.props.activeMessages.length > 0 && (
                <BroadcastBanner
                  activeMessages={this.props.activeMessages}
                  handleShow={this.handleShow}
                />
              )}
            <BroadcastMesssageDetails
              show={this.state.showMsgDetails}
              handleClose={this.handleClose}
              messages={this.props.activeMessages}
            />
          </header>
          <Switch>
            <Route
              exact
              path="/Dashboard"
              component={Dashboard}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView"
              component={FleetView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView"
              component={VehicleView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/VehicleProductsView"
              component={VehicleProductsView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/TractionConverterView"
              component={TractionConverterView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/VehicleView/VehicleProductsView/PackView"
              component={PackView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/FleetConfiguration"
              component={FleetSetup}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/FleetView/EmailAlertView"
              component={EmailAlertView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/ReportView"
              component={ReportView}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/Dashboard/SafeMode"
              component={SafeMode}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/Dashboard/Broadcast"
              component={Broadcast}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/access-denied"
              component={Unauthorized}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/helpdoc/"
              component={Help}
              isAuthenticated={true}
            />
            <Route
              exact
              path="/Glossary/:letter?"
              component={Glossary}
              isAuthenticated={true} // {this.props.logincheck}
            />
            <Route
              exact
              path="/RenewToken"
              component={RenewToken}
              isAuthenticated={true}
            />

            <Route exact path="/networkError" component={NetworkError} />
            <Route exact path="/serverError" component={ServerError} />
            <Route exact path="/" component={Dashboard} />
            {/* <PrivateRoute
              path="/"
              component={Dashboard}
              isAuthenticated={true}
            /> */}
            <Route path="**" component={NotFound} />
          </Switch>
          <footer className="footer">
            <VersionInfo />
          </footer>
        </Fragment>
      </Router>
    );
  }
}

// export
const mapStateToProps = createStructuredSelector({
  activeMessages: activeMessages(),
});
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);

// export default Routes;
