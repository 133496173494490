import Moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { HomeIcon } from '../../assets/images';
import { checkUserEntitlements } from '../../userConfig';
import { platformVersion } from '../../utils/excel-utils';
import './styles.scss';
class Breadcrumb extends React.Component {
  getSearchParams(key) {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(key);
  }

  getUrlPath() {
    const path = window.location.pathname;
    return path;
  }

  getQueryParamsAsString(params) {
    return new URLSearchParams(params).toString();
  }

  checkView() {
    return window.location.pathname !== '/Dashboard/ReportView';
  }

  render() {
    const startDate = Moment(new Date(Moment().subtract(15, 'days')))
      .format('YYYY-MM-DDTHH:mm:ss')
      .replace(/:/g, '%3A');
    const endDate = Moment(new Date(Moment().subtract(1, 'days')))
      .format('YYYY-MM-DDTHH:mm:ss')
      .replace(/:/g, '%3A');
    const fleet_params = {
      st: this.getSearchParams('st'),
      ft: this.getSearchParams('ft'),
      fty: this.getSearchParams('fty'),
      sdt: startDate,
      edt: endDate,
      srch: '',
      tb: 'Overview',
    };

    const vehicle_params = {
      st: this.getSearchParams('st'),
      ft: this.getSearchParams('ft'),
      fty: this.getSearchParams('fty'),
      vc: this.getSearchParams('vc'),
      sdt: startDate,
      edt: endDate,
      srch: '',
      vtb: 'Overview-vehicle-view',
    };

    const vehicle_product_params = {
      st: this.getSearchParams('st'),
      ft: this.getSearchParams('ft'),
      fty: this.getSearchParams('fty'),
      vc: this.getSearchParams('vc'),
      vp: this.getSearchParams('vp'),
      pip: this.getSearchParams('pip'),
      pdn: this.getSearchParams('pdn'),
      bpu: this.getSearchParams('bpu'),
      sdt: startDate,
      edt: endDate,
      srch: '',
      unmas: false,
      tb: 'Overview-vehicleprod-view',
    };
    const p3_vehicle_product_params = {
      st: this.getSearchParams('st'),
      ft: this.getSearchParams('ft'),
      fty: this.getSearchParams('fty'),
      vc: this.getSearchParams('vc'),
      vp: this.getSearchParams('vp'),
      pdn: this.getSearchParams('pdn'),
      sdt: startDate,
      edt: endDate,
      srch: '',
      unmas: false,
      vtb: 'Overview-vehicle-view',
    };
    let selectedBattery;
    if (
      sessionStorage.getItem('selectedProductDname') !== null &&
      sessionStorage.getItem('selectedProductDname') !== 'null'
    ) {
      const str = sessionStorage.getItem('selectedProductDname');
      const arr = str.split('_');
      arr.pop();
      selectedBattery = str;
    }

    return (
      <div>
        <div
          className="noLeftpadding noRightpadding"
          style={{
            position: 'fixed',
            zIndex: 99,
            background: 'rgb(244 244 244)',
            marginTop: '-10px',
            padding: '19px 8px',
            width: '98%',
            top: '50px',
            height: '65px',
          }}>
          <span className="pageTitle boldText  float-left">
            <span>
              <HomeIcon className={''} alt="Breadcrumbicon" />
            </span>
            <span className="breadcrumbPath">
              {(() => {
                switch (this.getUrlPath()) {
                  case '/FleetView/VehicleView/VehicleProductsView/PackView':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        <NavLink
                          to={`/FleetView?${this.getQueryParamsAsString(
                            fleet_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('ft') === null
                            ? 'Fleet view \xa0/\xa0'
                            : this.getSearchParams('ft') + '\xa0/\xa0'}
                        </NavLink>
                        <NavLink
                          to={`/FleetView/VehicleView?${this.getQueryParamsAsString(
                            vehicle_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('vc') === null
                            ? 'Vehicle view \xa0/\xa0'
                            : this.getSearchParams('vc') + '\xa0/\xa0'}
                        </NavLink>
                        <NavLink
                          to={`/FleetView/VehicleView${platformVersion() !== '3' ? '/VehicleProductsView' : ''}?${this.getQueryParamsAsString(
                            platformVersion() !== '3'
                              ? vehicle_product_params
                              : p3_vehicle_product_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('vp') === null
                            ? 'Product view \xa0/\xa0'
                            : this.getSearchParams('pdn') + '\xa0/\xa0'}
                        </NavLink>
                        {this.getSearchParams('pkdn') === null
                          ? 'Pack View'
                          : this.getSearchParams('pkdn')}
                      </span>
                    );
                  case '/FleetView/VehicleView/VehicleProductsView':
                  case '/FleetView/VehicleView/TractionConverterView':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        <NavLink
                          to={`/FleetView?${this.getQueryParamsAsString(
                            fleet_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('ft') === null
                            ? 'Fleet view \xa0/\xa0'
                            : this.getSearchParams('ft') + '\xa0/\xa0'}
                        </NavLink>
                        <NavLink
                          to={`/FleetView/VehicleView?${this.getQueryParamsAsString(
                            vehicle_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('vc') === null
                            ? 'Vehicle view \xa0/\xa0'
                            : this.getSearchParams('vc') + '\xa0/\xa0'}
                        </NavLink>
                        {this.getSearchParams('vp') === null
                          ? 'Vehicle product view'
                          : selectedBattery}
                      </span>
                    );
                  case '/FleetView/VehicleView':
                    return this.getSearchParams('vp') === 'null' ||
                      this.getSearchParams('vp') === null ? (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        <NavLink
                          to={`/FleetView?${this.getQueryParamsAsString(
                            fleet_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('ft') === null
                            ? 'Fleet view \xa0/\xa0'
                            : this.getSearchParams('ft') + '\xa0/\xa0'}
                        </NavLink>
                        {this.getSearchParams('vc') === null
                          ? 'Vehicle view'
                          : this.getSearchParams('vc')}
                      </span>
                    ) : (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        <NavLink
                          to={`/FleetView?${this.getQueryParamsAsString(
                            fleet_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('ft') === null
                            ? 'Fleet view \xa0/\xa0'
                            : this.getSearchParams('ft') + '\xa0/\xa0'}
                        </NavLink>
                        <NavLink
                          to={`/FleetView/VehicleView?${this.getQueryParamsAsString(
                            vehicle_params
                          )}`}
                          className="activebreadcrumb">
                          {this.getSearchParams('vc') === null
                            ? 'Vehicle view \xa0/\xa0'
                            : this.getSearchParams('vc') + '\xa0/\xa0'}
                        </NavLink>
                        {this.getSearchParams('vp') === null
                          ? 'Vehicle product view'
                          : selectedBattery}
                      </span>
                    );
                  case '/FleetView':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {this.getSearchParams('ft') === null
                          ? 'Fleet view'
                          : this.getSearchParams('ft')}
                      </span>
                    );
                  case '/Dashboard/FleetConfiguration':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {'FleetConfiguration'}
                      </span>
                    );
                  case '/Dashboard':
                    return <span className="breadcrumbstyle">Dashboard</span>;
                  case '/FleetView/EmailAlertView':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {/* <NavLink to="/FleetView" className="activebreadcrumb">
                          {this.getSearchParams("ft") === null
                            ? "Fleet view \xa0/\xa0"
                            : this.getSearchParams("ft") + "\xa0/\xa0"}
                        </NavLink> */}
                        {'Email Alert Setting'}
                      </span>
                    );
                  case '/Dashboard/ReportView':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {'Fleet report'}
                      </span>
                    );
                  case '/Dashboard/SafeMode':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {'Safe Mode'}
                      </span>
                    );
                  case '/Dashboard/Broadcast':
                    return (
                      <span className="breadcrumbstyle">
                        <NavLink to="/Dashboard" className="activebreadcrumb">
                          {`Dashboard / `}
                        </NavLink>
                        {'Broadcast'}
                      </span>
                    );
                  default:
                    return 'Dashboard';
                }
              })()}
            </span>
          </span>
          {checkUserEntitlements('ESS:Dashboard-Vehicleinformation') &&
            this.checkView() && (
              <span className="monitered-count float-right">
                <span className="boldText">
                  {this.props.MonitoredCount !== null &&
                  this.props.MonitoredCount !== undefined
                    ? this.props.MonitoredCount[0].sc
                    : 0}
                </span>
                {this.props.MonitoredCount !== null &&
                this.props.MonitoredCount !== undefined &&
                this.props.MonitoredCount[0].sc > 1
                  ? 'sites including'
                  : 'site including'}
                <span className="boldText">
                  {this.props.MonitoredCount !== null &&
                  this.props.MonitoredCount !== undefined
                    ? this.props.MonitoredCount[0].fc
                    : 0}
                </span>
                {this.props.MonitoredCount !== null &&
                this.props.MonitoredCount !== undefined &&
                this.props.MonitoredCount[0].fc > 1
                  ? 'fleets and'
                  : 'fleet and'}
                <span className="boldText">
                  {this.props.MonitoredCount !== null &&
                  this.props.MonitoredCount !== undefined
                    ? this.props.MonitoredCount[0].vc
                    : 0}
                </span>

                {this.props.MonitoredCount !== null &&
                this.props.MonitoredCount !== undefined &&
                this.props.MonitoredCount[0].vc > 1
                  ? 'vehicles monitored'
                  : 'vehicle monitored'}
              </span>
            )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({});

export default withRouter(connect(mapStateToProps)(Breadcrumb));
