import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ArrowDownLine } from '../../assets/images';
import './styles.scss';

class Glossary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      glossaryData: [],
      isVisible: false,
    };
    this.topRef = React.createRef();
    this.modalRef = React.createRef();
  }

  componentDidMount() {
    this.loadGlossaryContent();
    this.modalRef.current.addEventListener(
      'scroll',
      this.toggleBackToTopVisibility
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.redirectToDataFromLetter();
    }
  }

  componentWillUnmount() {
    this.modalRef.current.removeEventListener(
      'scroll',
      this.toggleBackToTopVisibility
    );
  }

  toggleBackToTopVisibility = () => {
    const modal = this.modalRef.current;
    if (modal.scrollTop > 400) {
      this.setState({ isVisible: true });
    } else {
      this.setState({ isVisible: false });
    }
  };

  loadGlossaryContent = () => {
    fetch(`${process.env.PUBLIC_URL}/glossary.txt`)
      .then(response => response.text())
      .then(text => {
        const glossaryData = this.parseGlossaryContent(text);
        this.setState({ glossaryData }, this.redirectToDataFromLetter);
      })
      .catch(error => {
        console.error('error', error);
      });
  };

  parseGlossaryContent = text => {
    const glossaryDatas = {};
    const formattedDatas = text.split('\n');
    formattedDatas.forEach(data => {
      const [key, value] = data.split(':').map(item => item.trim());
      if (key && value) {
        const zeroPositionLetter = key.charAt(0).toUpperCase();
        if (!glossaryDatas[zeroPositionLetter]) {
          glossaryDatas[zeroPositionLetter] = [];
        }
        glossaryDatas[zeroPositionLetter].push({ key, value });
      }
    });

    const glossaryArray = Object.keys(glossaryDatas)
      .sort()
      .map(letter => ({
        letter,
        items: glossaryDatas[letter],
      }));

    return glossaryArray;
  };

  scrollToTop = () => {
    if (this.topRef.current) {
      // this.props.history.push('/Glossary/A');
      this.topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  scrollToLetter = letter => {
    const tElement = document.getElementById(letter);
    if (tElement) {
      tElement.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`error`);
    }
  };

  redirectToDataFromLetter = () => {
    const { letter } = this.props.match.params;
    if (letter) {
      this.scrollToLetter(letter.toUpperCase());
    }
  };

  render() {
    const { glossaryData, isVisible } = this.state;

    return (
      <>
        <div className="modal-overlay">
          <div className="modal-container" ref={this.modalRef}>
            <div ref={this.topRef}></div>
            <button
              className="close-button"
              onClick={() => {
                // this.props.history.push('/Dashboard');
                this.props.history.goBack();
              }}>
              &times;
            </button>
            <span className="redUnderline"></span>

            <div className="modal-header">
              <h1 className="modal-heading">
                Glossary of technical keys commonly used by ABB
              </h1>
            </div>
            <div className="modal-content">
              <div className="alphabet-navigation">
                {'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('').map(letter => (
                  <span
                    key={letter}
                    className="alphabet-letter"
                    onClick={() => this.scrollToLetter(letter)}>
                    {letter}
                  </span>
                ))}
              </div>

              <div className="glossary-items">
                {glossaryData.map(group => (
                  <div
                    key={group.letter}
                    id={group.letter}
                    className="glossary-section">
                    <span className="redUnderlineSmall"></span>

                    <h2>{group.letter}</h2>
                    {group.items.map((item, index) => (
                      <p key={index}>
                        <strong>{item.key}:</strong> {item.value}
                      </p>
                    ))}
                    <button
                      className="back-to-top-button"
                      onClick={this.scrollToTop}>
                      Back to the top
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="backtotopdiv">
              {isVisible && (
                <span
                  onClick={this.scrollToTop}
                  className="backToTop"
                  style={{
                    bottom: isVisible ? '20px' : '-100px',
                    opacity: isVisible ? 1 : 0,
                    transition:
                      'bottom 0.5s ease-in-out, opacity 0.5s ease-in-out',
                  }}>
                  <ArrowDownLine style={{ transform: 'rotate(180deg)' }} />
                </span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Glossary);
